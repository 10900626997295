import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicesPage = (props) => (
      <Layout>
        <SEO description="BuilderCover can connect you with vendors across various industies. We are built to make your construction project smoother."
        title="Available Services for Construction Projects" 
        keywords={[`construction`, `building services`, `engineering`, `construction insurance`, `general contracting`, `material acquisition`, `construction finance`]} />
        <section className="hero is-small is-primary">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-1 is-hidden-touch">
                </div>
                <div className="column is-8">
                  <h2 className="subtitle is-size-4 is-uppercase has-text-info">
                    Available Services
                  </h2>
                  <h1 className="title has-text-white is-size-1-desktop is-size-2-touch has-text-weight-bold">
                    The help you need.<br/> When you need it.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div class="columns is-multiline container is-horizontal-centered">
            <div class="column is-4">
              <div class="card shadow">
                <div class="card-image">
                  <figure class="image">
                    <Img fluid={props.data.engImage.childImageSharp.fluid} alt="Engineering"/>
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-4">Design and Engineering</p>
                    </div>
                  </div>
                  <div class="content has-text-left">
                    Our partner engineering firms offer top quality Mechanical, Electrical, Plumbing, Sprinkler, 
                    and Fire Protection Engineering services, as well as Building Condition Assessments & Commissioning, 
                    LEED Consulting, Energy Modeling, Engineering Design Reviews, and much more.
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="card shadow">
                <div class="card-image">
                  <figure class="image">
                    <Img fluid={props.data.insuranceImage.childImageSharp.fluid} alt="Construction Insurance"/>
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-4">Insurance and Risk Management</p>
                    </div>
                  </div>
                  <div class="content has-text-left">
                  Insurance is needed for every single construction project. In many instances, 
                  it is a requirement in order to be awarded a job or permit. BuilderCover's construction 
                  insurance agents can provide coverage for material, risks, natural disasters, employees, and even your own business.
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="card shadow">
                <div class="card-image">
                  <figure class="image">
                    <Img fluid={props.data.financeImage.childImageSharp.fluid} alt="Construction Finance"/>
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-4">Construction Finance</p>
                    </div>
                  </div>
                  <div class="content has-text-left">
                    Whether you are seeking a short term loan or long term debt/equity financing, BuilderCover's
                    nationwide network of lenders can accommodate the needs of your project. No construction project, especially those that 
                    are large in scale, can proceed successfully without adequate financing.
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="card shadow">
                <div class="card-image">
                  <figure class="image">
                    <Img fluid={props.data.legalImage.childImageSharp.fluid} alt="Construction Legal Services"/>
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-4">Legal Services</p>
                    </div>
                  </div>
                  <div class="content has-text-left">
                    BuilderCover's construction law attorneys assist a wide variety of clients over the course of many
                    construction projects. There are a ton of different 
                    reasons these lawyers might be needed, but workmanship issues and payment disputes are two 
                    of the most common areas of construction law.
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="card shadow">
                <div class="card-image">
                  <figure class="image">
                    <Img fluid={props.data.materialsImage.childImageSharp.fluid} alt="Construction Materials"/>
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-4">Material Acquisition</p>
                    </div>
                  </div>
                  <div class="content has-text-left">
                  Materials vendors with BuilderCover offer products and services spanning
                  building materials, including millwork and structural component manufacturing capabilities, 
                  consultative showrooms and design centers, and value-added installation management capable of supporting all of our clients needs.
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="card shadow">
                <div class="card-image">
                  <figure class="image">
                    <Img fluid={props.data.gcImage.childImageSharp.fluid} alt="General Contractor and Subcontractors"/>
                  </figure>
                </div>
                <div class="card-content">
                  <div class="media">
                    <div class="media-content">
                      <p class="title is-4">General Contractors and Subs</p>
                    </div>
                  </div>
                  <div class="content has-text-left">
                    You need people to build. BuildCover connects the general
                    contractors and subcontractors eager to move your construction project forward. By
                    applying our construction knowledge and expertise to the process, BuilderCover contractors maintain the cost, schedule and quality of the project.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> 
      </Layout>
    )

export default ServicesPage

export const pageQuery = graphql `
  query {
    engImage: file(relativePath: { eq: "engineering.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    insuranceImage: file(relativePath: { eq: "insurance.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    financeImage: file(relativePath: { eq: "finance.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    legalImage: file(relativePath: { eq: "legal.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    materialsImage: file(relativePath: { eq: "materials.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gcImage: file(relativePath: { eq: "gc.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;